$primary: #009691;

.subscribers__container {
  background-color: $primary;
  padding: 20px 50px;
  border-radius: 5px;

  & p {
    text-align: center;
    color: white;
    font-family: 'Roboto';
  }

  & i {
    color: white;
    font-size: 44px;
  }

  @media (max-width: 320px) {
    padding: 10px;
  }
}
