.blog__container {
  @media (min-width: 1000px) {
    width: 1000px;
  }
  @media (max-width: 800px) {
    padding: 0 25px;
  }
}

.blog__title {
  margin-top: 5px;
}

.blog-card__time {
  font-size: 14px;

  &--small {
    font-size: 12px;
  }
}

.blog-card__video--clicker {
  position: absolute;
  width: 100%;
  height: 100%;
}

.blog-card__description--small {
  font-size: 12px !important;
  margin: 6px 0;
  width: 100%;
}
