$primary: #009691;

.about--activate {
  background: $primary;
  color: white;
}

.about__text--width {
  max-width: 960px;
}

.about--hide {
  display: none;
}
