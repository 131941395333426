.our-team__cover-video {
  width: 100vw;
  height: 65vh;
}

.our-team__member {
  width: 250px;
}

.our-team__members--container {
  margin-top: -100px;
  padding: 0 20px;
  z-index: 1;
  justify-content: space-around;
  @media (max-width: 1366px) {
    margin-top: 10px;
    justify-content: space-between;
  }
  @media (max-width: 425px) {
    justify-content: center;
  }
}
