$gray: #777;

.blog-card {
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  background: #fff;
  height: 100%;

  &--rounded {
    border-radius: 4px;
    overflow: hidden;
  }
}

.blogs_card {
  width: 50%;
  padding: 10px 20px;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 15px;
  }
}

.blog-card__icon {
  margin: 0 10px;
}

.blog-card__footer {
  padding-left: 52px;
  padding-right: 65px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.blog_card__title {
  color: #555;
  font-size: 24px;
  &--small {
    margin: 3px 0 6px 0;
    font-size: 14px;
  }
}

.blog_card__description {
  width: 100%;
  & body {
    max-height: 250px;
    overflow: hidden;
  }
}

.blog_card__description > * {
  color: $gray;

  &--small {
    font-size: 12px;
  }
}

.blog-card__link--hover {
  cursor: pointer;
}
