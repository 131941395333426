
.feed {
  @media(max-width: 1024px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.feed-container {
  @media (min-width: 1000px) {
    width: 1000px;
  }
  @media (max-width: 768px) {
    padding: 0 10px;
  }
}

.feed__counter {
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
}

.feed__subscribe {
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
}
$primary: #009691;

.feed__blog {
  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.feed__timer-text {
  & p {
    font-family: "Roboto";
    margin: 0;
    text-align: center;
  }
}

.feed__card {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  cursor: pointer;
  &:hover{
    background: rgba(0, 0, 0, 0.4);
  }
}

.feed__download-section {
  background-color: $primary;
  width: 100vw;  
  height: 150px;
  margin-top: 5rem;
}